<template>
  <div>
    <div class="result-panel">
      <CSTable>
        <template v-slot:header>
          <div class="table-header-panel" style="text-align: right">
            <button
                class="btn btn-primary sticky-right"
                @click="openAddCouponDialog"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              停车券
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>停车费抵扣<span>（元）</span></th>
            <th>用户领取后有效时限</th>
            <th>用户每天可领取张数</th>
            <th>配图</th>
            <th>已领人次</th>
            <th>已领数量</th>
            <th>数量</th>
            <th>已过期数量</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="parkingCoupons.length > 0">
            <tr v-for="coupon in parkingCoupons" :key="coupon.id">
              <td class="date-td">{{ coupon.createTime }}</td>
              <td>{{ coupon.deductionMoney }}</td>
              <td>
                {{
                  coupon.effectiveTime == 0
                      ? "不限"
                      : coupon.effectiveTime + "小时"
                }}
              </td>
              <td>{{ coupon.dailyDraw }}</td>
              <td>
                <span
                    v-if="coupon.picture"
                    class="allow-click"
                    @click="lookCoupon(coupon.picture)"
                >查看</span
                >
                <template v-else>-</template>
              </td>
              <td>
                <span
                    v-if="coupon.receivePeople"
                    class="allow-click"
                    @click="lookReceiveCoupon(coupon.id)"
                >
                  {{ coupon.receivePeople }}
                </span>
                <span v-else>
                  {{ coupon.receivePeople }}
                </span>
              </td>
              <td>{{ coupon.receiveNum }}</td>
              <td>{{ coupon.useNum }}</td>
              <td>{{ coupon.overdueNum }}</td>
              <td>{{ coupon.state === 1 ? "启用" : "禁用" }}</td>
              <td>
                <!-- <button class="btn btn-primary" @click="changeCouponState(coupon.state, coupon.id)">
                                    {{coupon.state === 1 ? '禁用' : '启用'}}
                                </button> -->
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 76px; padding: 0"
                  >
                    <li
                        @click="changeCouponState(coupon.state, coupon.id)"
                    >
                      <a style="width: 100%; padding: 3px 0">
                        {{ coupon.state === 1 ? "禁用" : "启用" }}
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <pagination/>
    </div>

    <CSDialog
        :dialog-visible="addDialogVisible"
        dialog-title="添加停车券"
        dialog-width="570px"
        @onClose="addDialogVisible = false"
        @onConfirm="postAddCouponRequest"
    >
      <template v-slot:dialog-content>
        <div class="coupons-form">
          <div class="item">
            <div class="item-name">停车费抵扣(元)</div>
            <div>
              <CSSelect height="40px">
                <select
                    v-model="addCouponParams.deductionMoney"
                    style="width: 145px;color: #999999"
                >
                  <option value="">请选择</option>
                  <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="item">
            <div class="item-name">用户领取后有效时限</div>
            <div>
              <CSSelect height="40px">
                <select
                    v-model="addCouponParams.effectiveTime"
                    style="width: 145px;color: #999999"
                >
                  <option value="">请选择</option>
                  <option
                      v-for="option in effectiveTimeOption"
                      :key="option.val"
                      :value="option.val"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="item">
            <div class="item-name">用户每天可领取张数</div>
            <div>{{ addCouponParams.dailyDraw }}</div>
          </div>
          <div class="item">
            <div class="item-name top">配图</div>
            <div class="top">
              <div
                  v-if="addCouponParams.picture === ''"
                  class="upload-photo"
                  style="width: 180px"
                  @click="uploadImage()"
              >
                <img
                    alt=""
                    src="../../assets/upload.png"
                    style="margin: 10px auto"
                />
                <p style="color: #999999">720*260</p>
              </div>
              <div
                  v-else
                  class="photo-view"
                  style="width: 180px; height: 80px"
                  @click="uploadImage()"
              >
                <img :src="addCouponParams.picture" alt=""/>
              </div>
              <input hidden type="file" @change="uploadFileListen"/>
            </div>
          </div>
          <div class="tip">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            添加后，将无法修改信息，请仔细认真检查所填信息。
          </div>
        </div>
      </template>
    </CSDialog>
    <ViewImageModal/>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import {addParkingCouponUrl, changeParkingCouponEnableUrl, queryParkingCouponUrl,} from "@/requestUrl";
import ViewImageModal from "@/components/ViewImageModal";
import CSTable from "@/components/common/CSTable";

export default {
  name: "ParkCoupons",
  components: {CSTable, ViewImageModal, CSSelect, CSDialog, Pagination},
  created() {
    this.queryCoupons();
    document.body.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.queryCoupons();
      }
    });
    this.$vc.on(
        this.$route.path,
        "pagination_page",
        "event", page => {
          this.pageParams.pageNo = page
          this.queryCoupons(page)
        });
  },
  data() {
    return {
      parkingCoupons: [],
      addCouponParams: {
        deductionMoney: "",
        effectiveTime: "",
        dailyDraw: 1,
        picture: "",
      },
      addDialogVisible: false,
      effectiveTimeOption: [
        {val: 24, name: "24小时"},
        {val: 3 * 24, name: "3天"},
        {val: 7 * 24, name: "7天"},
        {val: 15 * 24, name: "15天"},
        {val: 30 * 24, name: "30天"},
        {val: 0, name: "不限"},
      ],
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  methods: {
    lookCoupon(src) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", src);
    },
    /**
     * 查看优惠券领取记录
     * @param {Number} id 优惠券id
     * */
    lookReceiveCoupon(id) {
      this.$router.push({
        name: "parkCouponsLog",
        params: {
          id,
        },
      });
    },
    openAddCouponDialog() {
      this.addDialogVisible = true;
      this.addCouponParams = {
        deductionMoney: "",
        effectiveTime: "",
        dailyDraw: 1,
        picture: "",
      };
    },
    postAddCouponRequest() {
      const {deductionMoney, effectiveTime, dailyDraw, picture} =
          this.addCouponParams;
      if (deductionMoney === "") {
        this.$vc.message("请选择抵扣金额");
        return;
      }
      if (effectiveTime === "") {
        this.$vc.message("请选择有效时间");
        return;
      }
      if (picture === "") {
        this.$vc.message("请上传优惠券配图");
        return;
      }
      this.$fly
          .post(addParkingCouponUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            deductionMoney,
            effectiveTime,
            dailyDraw,
            picture,
          })
          .then((res) => {
            if (res.code === 0) {
              this.addDialogVisible = false;
              this.$vc.toast("添加成功");
              this.queryCoupons();
            }
          });
    },
    /**
     * 上传文件监听
     * @param {Event} e 事件对象
     * */
    async uploadFileListen(event) {
      // const { files } = e.target,
      // file = files[0];
      // const formData = new FormData();
      // formData.append("file", file);
      // this.$fly
      //   .upload(uploadFileUrl, formData)
      //   .then((res) => {
      //     if (res.code === 0) {
      //       this.addCouponParams.picture = commonImgOssDomain + res.data.ossUrl;
      //     }
      //   })
      //   .finally(() => {
      //     e.target.value = "";
      //   });
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2M!");
          return false;
        }
        let formData = new FormData(); //新建FormData对象
        formData.append("files", photoFiles[0]);
        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
        formData.append("compress", "true");
        const photo = await this.Constants.uploadFileOne(formData);
        if (photo) {
          this.addCouponParams.picture = this.Constants.BUCKET_NAMES.IMG.visitUrl + photo;
        }
        event.target.value = ""

      }
    },
    /**
     * 更改优惠券状态
     * @param {Number} state 优惠券当前状态 1:启用 0：禁用
     * @param {Number} id 优惠券id
     * */
    changeCouponState(state, id) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定${state === 1 ? "禁用" : "启用"}优惠券吗？`,
        onConfirm: () => {
          this.$fly
              .post(changeParkingCouponEnableUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                id,
                isEnable: Number(!state),
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$vc.toast(`${state === 1 ? "禁用" : "启用"}成功`);
                  this.$CSDialog.instance.closeDialog();
                  this.queryCoupons();
                }
              });
        },
      });
    },
    /**
     * 后台查询停车券接口
     * @param {Number} pageNo 页码
     * @param {Number} pageSize 查询的数量
     * */
    queryCoupons(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(queryParkingCouponUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize,
                currentPage: pageNo,
              });
            }
            this.parkingCoupons = res.data.datas ?? [];
          });
    },
    uploadImage() {
      document.querySelector('input[type="file"]').click();
    },
  },
};
</script>

<style lang="stylus" scoped>
.coupons-form {
  padding: 16px 30px 20px 30px;
  line-height: 1;

  .item {
    font-size: 24px;

    & > div {
      display: inline-block;
      vertical-align: middle;

      &.top {
        vertical-align: top;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-name {
      width: 220px;
      margin-right: 30px;
      text-align: right;
    }
  }

  .tip {
    font-size: 20px;
    color: #999;
  }
}
</style>
